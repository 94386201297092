<template>
  <div v-loading="pageLoading">
    <div class="card mb-5 mb-xl-10" v-if="tradingPartnerID">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldTradingPartnerData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span class="d-flex align-items-center text-gray-400 me-5 mb-2" v-html="oldTradingPartnerData.description.length > 500 ? oldTradingPartnerData.description.substring(0,500)+'...' : oldTradingPartnerData.description"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-12">
        <el-form @submit.prevent="submitForm()" :model="formData" :rules="rules" ref="formRef" class="w-100">
          <input type="hidden" v-model="formData.trading_partner_id" />
          <input type="hidden" v-model="formData.userID" />
          <div class="row mb-10">
            <div class="col-md-3">
              <label class="fs-6 fw-bold mb-2">Select Partner Image</label>
              <div class="image_block">
                <div class="image-input image-input-outline" data-kt-image-input="true" style="background-image: url(/media/avatars/blank.png)">
                  <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${imageSrcPath})`"></div>
                  <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                    <i class="bi bi-pencil-fill fs-7"></i>
                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" @change="onFileChange" />
                    <input type="hidden" name="avatar_remove" />
                  </label>
                  <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" @click="removeImage()" title="Remove avatar">
                    <i class="bi bi-x fs-2"></i>
                  </span>
                </div>
                <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
                </div>
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <label class="fs-6 fw-bold mb-2">{{ constants.tradingPartner.SELECTPLATFORM }}</label>
                  <el-form-item prop="trading_platform_id">
                    <el-select v-model="formData.trading_platform_id" filterable placeholder="Select Trading Platform" size="large">
                    <el-option :label="platformData.platform_name" :value="platformData.trading_platform_id" :key="platformData"  v-for="platformData in platformData"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="col-md-4 mb-3">
                  <label class="required mb-1 fw-bold">{{ constants.tradingPartner.TRADINGPARTNERNAME }}</label>
                  <el-form-item prop="name">
                    <el-input size="large" v-model="formData.name" type="text" :placeholder="constants.tradingPartner.TRADINGPARTNERNAME"/>
                  </el-form-item>
                </div>
                <div class="col-md-4 mb-3">
                  <label class="required mb-1 fw-bold">{{ constants.general.EMAIL }}</label>
                  <el-form-item prop="email">
                    <el-input size="large" v-model="formData.email" type="email" :placeholder="constants.general.EMAIL"/>
                  </el-form-item>
                </div>
                <div class="col-md-4 mb-3">
                  <label class="required mb-1 fw-bold">{{ constants.tradingPartner.WEBSITEURL }}</label>
                  <el-form-item prop="website">
                    <el-input size="large" v-model="formData.website" type="text" :placeholder="constants.tradingPartner.WEBSITEURL"/>
                  </el-form-item>
                </div>
                <div class="col-md-4 mb-3">
                  <label class="required mb-1 fw-bold">{{ constants.tradingPartner.SHORTCODE }}</label>
                  <el-form-item prop="shortcode">
                    <el-input size="large" v-model="formData.shortcode" type="text" :placeholder="constants.tradingPartner.SHORTCODE"/>
                  </el-form-item>
                </div>
                <div class="col-md-4 mb-3">
                  <label class="required fw-bold">{{ constants.general.STATUS }}</label>
                  <el-form-item prop="status">
                    <el-switch v-model="formData.status" active-color="#13ce66" inactive-color="#ff4949"/>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-5" v-if="formData.grantType">
            <div class="col-md-2">
              <label class="fw-bold">{{ constants.tradingPartner.GRANTTYPE }}</label>
              <div>
                <label id="grantTypeID" ref="grantTypeRef">{{ formData.grantType }}</label>&nbsp;
                <button type="button" :id="'variable_0'" :data-clipboard-text="formData.grantType" class="btn btn-active-color-primary btn-icon btn-sm btn-outline-light d-">
                  <span class="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="black" />
                  </svg>
                  </span>
                </button>
              </div>
            </div>
            <div class="col-md-3">
              <label class="fw-bold">{{ constants.tradingPartner.CLIENTID }}</label>
              <div>
                <label id="clientID" ref="clientInputRef">{{ formData.clientID }}</label>&nbsp;
                <button type="button" :id="'variable_1'" :data-clipboard-text="formData.clientID" class="btn btn-active-color-primary btn-icon btn-sm btn-outline-light d-">
                  <span class="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="black" />
                  </svg>
                  </span>
                </button>
              </div>
            </div>
            <div class="col-md-4">
              <label class="fw-bold">{{ constants.tradingPartner.SECRETID }}</label>
              <div>
                <label id="secretID" ref="secretInputRef">{{ formData.secretID }}</label>&nbsp;
                <button type="button" :id="'variable_2'" :data-clipboard-text="formData.secretID" class="btn btn-active-color-primary btn-icon btn-sm btn-outline-light d-">
                  <span class="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="black" />
                  </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <fieldset class="p-3 mb-5 border-secondary bg-light">
            <div class="row">
              <legend class="fw-bold">{{ constants.tradingPartner.ART_SKU_REGEX }}:</legend>
              <div class="col-md-3">
                <label class="me-1">{{ constants.general.SKU_LENGTH }}</label>
                <el-tooltip class="box-item" effect="dark" :content="constants.general.SKU_LENGTH_HELP" placement="right" raw-content>
                  <i class="bi bi-question-circle-fill"></i>
                </el-tooltip>
                <el-form-item prop="sku_length">
                  <el-input size="large" v-model="formData.sku_length" type="text" :placeholder="constants.general.SKU_LENGTH" />
                </el-form-item>
              </div>
              <div class="col-md-3">
                <label class="me-1">{{ constants.general.ART_SKU_REGEX_PATTERN }}</label>
                <el-tooltip class="box-item" effect="dark" :content="constants.general.ART_SKU_REGEX_PATTERN_HELP" placement="right" raw-content>
                  <i class="bi bi-question-circle-fill"></i>
                </el-tooltip>
                <el-form-item prop="art_sku">
                  <el-input size="large" v-model="formData.art_sku" type="text" :placeholder="constants.general.ART_SKU_REGEX_PATTERN" />
                </el-form-item>
              </div>
              <div class="col-md-3">
                <label class="me-1">{{ constants.general.PRODUCT_SKU_REGEX_PATTERN }}</label>
                <el-tooltip class="box-item" effect="dark" :content="constants.general.PRODUCT_REGEX_PATTERN_HELP" placement="right" raw-content>
                  <i class="bi bi-question-circle-fill"></i>
                </el-tooltip>
                <el-form-item prop="product_sku">
                  <el-input size="large" v-model="formData.product_sku" type="text" :placeholder="constants.general.PRODUCT_SKU_REGEX_PATTERN" />
                </el-form-item>
              </div>
            </div>
          </fieldset>
          <el-collapse class="mb-10">
            <el-collapse-item title="Return Address:" name="1" class="fw-bold">
              <fieldset class="p-3 mb-5 border-secondary bg-light">
                <div class="row">
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.NAME }}</label>
                      <el-form-item prop="address_name">
                        <el-input size="large" v-model="formData.address_name" type="text" :placeholder="constants.tradingPartner.NAME" />
                      </el-form-item>
                  </div>
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.COMPANY }}</label>
                      <el-input size="large" v-model="formData.company" type="text" :placeholder="constants.tradingPartner.COMPANY" />
                  </div>
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.ADDRESS1 }}</label>
                      <el-form-item prop="address1">
                        <el-input size="large" v-model="formData.address1" type="text" :placeholder="constants.tradingPartner.ADDRESS1" />
                      </el-form-item>
                  </div>
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.ADDRESS2 }}</label>
                      <el-form-item prop="address2">
                        <el-input size="large" v-model="formData.address2" type="text" :placeholder="constants.tradingPartner.ADDRESS2" />
                      </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.CITY }}</label>
                      <el-form-item prop="city">
                        <el-input size="large" v-model="formData.city" type="text" :placeholder="constants.tradingPartner.CITY" />
                      </el-form-item>
                  </div>
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.STATE }}</label>
                      <el-form-item prop="state">
                        <el-input size="large" v-model="formData.state" type="text" :placeholder="constants.tradingPartner.STATE" />
                      </el-form-item>
                  </div>
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.STATE_CODE }}</label>
                      <el-form-item prop="state_code">
                        <el-input size="large" v-model="formData.state_code" type="text" :placeholder="constants.tradingPartner.STATE" />
                      </el-form-item>
                  </div>
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.COUNTRY }}</label>
                      <el-form-item prop="country">
                        <el-input size="large" v-model="formData.country" type="text" :placeholder="constants.tradingPartner.COUNTRY" />
                      </el-form-item>
                  </div>
                  <div class="col-md-3">
                      <label>{{ constants.tradingPartner.COUNTRY_CODE }}</label>
                      <el-form-item prop="country_code">
                        <el-input size="large" v-model="formData.country_code" type="text" :placeholder="constants.tradingPartner.COUNTRY_CODE" />
                      </el-form-item>
                  </div>
                  <div class="col-md-3">
                    <label>{{ constants.tradingPartner.POSTAL_CODE }}</label>
                      <el-form-item prop="postal_code">
                        <el-input size="large" v-model="formData.postal_code" type="text" :placeholder="constants.tradingPartner.POSTAL_CODE" />
                      </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <label>{{ constants.tradingPartner.PHONE }}</label>
                    <el-form-item prop="phone">
                      <el-input size="large" v-model="formData.phone" type="text" :placeholder="constants.tradingPartner.PHONE" />
                    </el-form-item>
                  </div>
                  
                </div>
              </fieldset>
            </el-collapse-item>
          </el-collapse>
          <div class="row g-9">
            <div class="col-md-12 fv-row">
              <button class="mb-2 btn btn-success float-end" @click="onAddItem"><i class="bi bi-plus-circle-fill fs-4"></i> {{ constants.tradingPartner.ADDAPIPERAMETERBTN }}</button>
              <table class="table table-row-dashed fs-6 datatable border gy-2 gs-2" v-if="formData.keyData.length > 0">
                <thead>
                  <tr class="text-start text-gray-800 fw-bolder fs-7 text-uppercase gs-0 text-center">
                    <th class="required">{{ constants.tradingPartner.PULLTYPE }}</th>
                    <th class="required">{{ constants.tradingPartner.KEYNAME }}</th>
                    <th class="required">{{ constants.tradingPartner.KEYVALUE }}</th>
                    <th>{{ constants.general.ACTIONS }}</th>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-600">
                  <tr v-for="(apidata, index) in formData.keyData" :key="index" v-bind:id="'row_'+index" align="center">
                    <td>
                      <el-form-item :prop="'keyData.' + index + '.pull_type'"
                        :rules="{
                          required: true,
                          message: constants.tradingPartner.TYPEREQUIRED,
                          trigger: 'change',
                        }">
                        <el-select v-model="apidata.pull_type" :placeholder="constants.tradingPartner.PULLTYPE" size="large">
                          <el-option
                          v-for="item in pullOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </td>
                    <td width="300">
                      <el-form-item :prop="'keyData.' + index + '.pull_key'"
                        :rules="{
                          required: true,
                          validator: checkEmptySpace,
                          message: constants.tradingPartner.KEYNAMEREQUIRED,
                          trigger: 'change',
                        }">
                        <el-input size="large" v-model="apidata.pull_key" :placeholder="constants.tradingPartner.KEYNAME" />
                      </el-form-item>
                    </td>
                    <td width="800">
                      <el-form-item :key="'key_value'+index" :prop="'keyData.' + index + '.pull_value'"
                        :rules="{
                          required: true,
                          validator: checkEmptySpace,
                          message: constants.tradingPartner.KEYVALUEREQUIRED,
                          trigger: 'change',
                        }">
                        <el-input size="large" v-model="apidata.pull_value" :placeholder="constants.tradingPartner.KEYVALUE" />
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :key="'key'+index">
                        <button type="button" @click.prevent="deleteRow(index)" :tabindex="index" class="btn btn-icon btn-light btn-sm me-2">
                          <i class="bi bi-trash text-danger"></i>
                        </button>
                      </el-form-item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-12 fv-row mb-5">
                <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))"  :disabled="loading" @click="submitForm(formRef, 'save')">{{constants.general.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </button>
                <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{constants.general.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'trading-partners'})">{{constants.general.CANCEL}}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { notificationFire } from "@/composable/notification.js";
import ApiService from "@/core/services/ApiService";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { useRoute, useRouter } from "vue-router";
import ClipboardJS from "clipboard";
import useEventBus from "../../composable/useEventBus";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { checkNumber } from "@/composable/common-validation.js";
import {
  loggedInUserRoles,
  loggedInUserPermissions,
} from "@/composable/get-roles.js";
export default {
  name: "add-edit-trading-partner",

  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const pageLoading = ref(false);
    const formRef = ref(null);
    const loadingback = ref(false);
    const loading = ref(false);
    const readonly = ref(true);
    const route = useRoute();
    const router = useRouter();
    const tradingPartnerID = ref(0);
    const constants = globalConstant;
    const platformData = reactive([]);
    const imageSrcPath = ref("/media/avatars/blank.png");
    const defaultImage = ref("/media/avatars/blank.png");
    const oldTradingPartnerData = reactive({
      name: '',
      description: ''
    });

    //Trading Partner form fields
    const formData = ref({
      trading_platform_id: "",
      trading_partner_id: "",
      partner_image_path: "",
      oldImagePath: "",
      userID: "",
      name: "",
      email: "",
      website: "",
      shortcode: "",
      status: true,
      clientID: "",
      secretID: "",
      address_name: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      state_code: "",
      country: "",
      country_code: "",
      phone: "",
      postal_code: "",
      sku_length: '',
      art_sku: '',
      product_sku: '',
      keyData: [],
    });

    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
    });

    const tableData = ref([
      {
        pull_key: "",
        pull_value: "",
      },
    ]);

    const pullOptions = [
      {
        value: "endpoint",
        label: "End Point",
      },
      {
        value: "headers",
        label: "Headers",
      },
      {
        value: "body",
        label: "Body",
      },
    ];

    const removeImage = () => {
      imageSrcPath.value = defaultImage.value;
      formData.value.partner_image_path = "";
      formData.value.oldImagePath = "";
    };

    const onFileChange = (event) => {
      formData.value.partner_image_path = event.target.files[0];
      imageSrcPath.value = URL.createObjectURL(event.target.files[0]);
    };

    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "save") {
        submitForm(formRef, "save", true);
      } else if (actionName == "save_back") {
        submitForm(formRef, "save_back", true);
      } else if (actionName == "cancel") {
        router.push({ name: "trading-partners" });
      }
    });

    const checkEmptySpace = (rule, value, callback) => {
      if (value.trim() === "") {
        callback(new Error("Key name is required"));
      } else {
        callback();
      }
    };

    //Add row for extra field table
    const onAddItem = () => {
      formData.value.keyData.push({
        pull_type: "",
        pull_key: "",
        pull_value: "",
      });
    };

    //Delete row for extra field table
    const deleteRow = (index) => {
      formData.value.keyData.splice(index, 1);
    };

    //Rules for trading partner form
    const rules = ref({
      trading_platform_id: [
        {
          required: true,
          message: "Trading platform is required",
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          validator: checkEmptySpace,
          message: "Trading partner name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
        {
          type: "email",
          message: "Please enter a valid email address.",
          trigger: ["blur", "change"],
        },
      ],
      website: [
        {
          required: true,
          message: "Website is required",
          trigger: "blur",
        },
        {
          type: "url",
          message: "The website format is invalid",
          trigger: ["blur", "change"],
        },
      ],
      shortcode: [
        {
          required: true,
          validator: checkEmptySpace,
          message: "Shortcode is required",
          trigger: "change",
        },
      ],
    });

    //To remove trading partner details
    const remove = async (id, rownum) => {
      await ApiService.delete("/trading-partner/" + id)
        .then((response) => {
          notificationFire(response.data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response != "") {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Get Trading Partner Details By ID
    const getTradingPartnerDetail = async () => {
    if(typeof route.params.id != "undefined"){
      pageLoading.value = true;
      await ApiService.query("trading-partner/" + route.params.id)
        .then(({ data }) => {
          if (data.data.tradingPartner) {
            formData.value.trading_platform_id = data.data.tradingPartner.trading_platform_id != '' ? data.data.tradingPartner.trading_platform_id : ''; 
            formData.value.trading_partner_id = data.data.tradingPartner.trading_partner_id;
            formData.value.userID = data.data.tradingPartner.user_id;
            if(data.data.tradingPartner.get_user_oauth.length > 0){
            formData.value.name = oldTradingPartnerData.name = data.data.tradingPartner.get_user_oauth[0].name;
            formData.value.email = oldTradingPartnerData.description = data.data.tradingPartner.get_user_oauth[0].email;
            formData.value.clientID = data.data.tradingPartner.get_user_oauth[0].oathID;
            formData.value.secretID = data.data.tradingPartner.get_user_oauth[0].secret;
            }
            imageSrcPath.value = data.data.tradingPartner.partner_image_path == null ? defaultImage.value : process.env.VUE_APP_API_URL + "../../../partner_image/" + data.data.tradingPartner.partner_image_path;
            formData.value.oldImagePath = data.data.tradingPartner.partner_image_path;
            formData.value.website = data.data.tradingPartner.website;
            formData.value.shortcode = data.data.tradingPartner.shortcode;
            formData.value.status = data.data.tradingPartner.status == "1" ? true : false;
            formData.value.grantType = "client_credentials";
            if(data.data.tradingPartner.sku_regex != null){
              formData.value.sku_length = data.data.tradingPartner.sku_regex.sku_length;
              formData.value.art_sku = data.data.tradingPartner.sku_regex.art_sku;
              formData.value.product_sku = data.data.tradingPartner.sku_regex.product_sku;
            }
            if(data.data.tradingPartner.store_info != null){
              formData.value.keyData = [];
              let storeInfo = data.data.tradingPartner.store_info;
              for (const key in storeInfo.endpoint) {
                formData.value.keyData.push({
                  pull_type: 'endpoint',
                  pull_key: key,
                  pull_value: storeInfo.endpoint[key],
                });
              }
              formData.value.website = data.data.tradingPartner.website;
              formData.value.shortcode = data.data.tradingPartner.shortcode;
              formData.value.status =
                data.data.tradingPartner.status == "1" ? true : false;
              formData.value.grantType = "client_credentials";

              if (data.data.tradingPartner.store_info != null) {
                formData.value.keyData = [];
                let storeInfo = data.data.tradingPartner.store_info;
                for (const key in storeInfo.endpoint) {
                  formData.value.keyData.push({
                    pull_type: "endpoint",
                    pull_key: key,
                    pull_value: storeInfo.endpoint[key],
                  });
                }
                for (const key in storeInfo.headers) {
                  formData.value.keyData.push({
                    pull_type: "headers",
                    pull_key: key,
                    pull_value: storeInfo.headers[key],
                  });
                }
                for (const key in storeInfo.body) {
                  formData.value.keyData.push({
                    pull_type: "body",
                    pull_key: key,
                    pull_value: storeInfo.body[key],
                  });
                }
              }
            }

            if(data.data.tradingPartner.address != null){
              formData.value.address_name = data.data.tradingPartner.address.name;
              formData.value.company = data.data.tradingPartner.address.company;
              formData.value.address1 = data.data.tradingPartner.address.address1;
              formData.value.address2 = data.data.tradingPartner.address.address2;
              formData.value.city = data.data.tradingPartner.address.city;
              formData.value.state = data.data.tradingPartner.address.state;
              formData.value.state_code = data.data.tradingPartner.address.state_code;
              formData.value.country = data.data.tradingPartner.address.country;
              formData.value.country_code = data.data.tradingPartner.address.country_code;
              formData.value.phone = data.data.tradingPartner.address.phone_number;
              formData.value.postal_code = data.data.tradingPartner.address.postal_code;
            }

            if (data.data.tradingPlatforms.length > 0) {
              data.data.tradingPlatforms.forEach((obj) => {
                platformData.push({
                  trading_platform_id: obj.trading_platform_id,
                  platform_name: obj.platform_name,
                });
              });
            }
          pageLoading.value = false;
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response != "") {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
            if (status == 404 || status == 422) {
              router.push({ name: 'trading-partners'})
            }
          }
          pageLoading.value = false;
        });
      }
    };

    //Fetch trading platform data
    const getTradingPlatforms = async () => {
      await ApiService.post("trading-partner/fetch-trading-platforms")
        .then(({ data }) => {
          if (data.data.length > 0) {
            data.data.forEach((obj) => {
              platformData.push({
                trading_platform_id: obj.trading_platform_id,
                platform_name: obj.platform_name,
              });
            });
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response != "") {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
            if (status == 404 || status == 422) {
              router.push({ name: "trading-partners" });
            }
          }
          pageLoading.value = false;
        });
    };

    //Submit form for trading partner
    const submitForm = async (
      formEl,
      btnName,
      isToolbarButtonsSubmitAction = false
    ) => {
      formEl = isToolbarButtonsSubmitAction ? formEl.value : formEl;
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (!valid) return false;
        isToolbarButtonsSubmitAction
          ? emitEvent("loader", { save_loader: true, action: btnName })
          : btnName == "save"
          ? (loading.value = true)
          : (loadingback.value = true);
        let requestUrl =
          typeof route.params.id === "undefined"
            ? "trading-partner"
            : "trading-partner/" + route.params.id;
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        let formDataSubmit = new FormData();
        formDataSubmit.append("_method", typeof route.params.id === "undefined" ? "POST" : "PUT");
        formDataSubmit.append("trading_platform_id", formData.value.trading_platform_id);
        formDataSubmit.append("trading_partner_id", formData.value.trading_partner_id);
        formDataSubmit.append("userID", formData.value.userID);
        formDataSubmit.append("name", formData.value.name);
        formDataSubmit.append("email", formData.value.email);
        formDataSubmit.append("website", formData.value.website);
        formDataSubmit.append("shortcode", formData.value.shortcode);
        formDataSubmit.append("status", formData.value.status == true ? 1 : 0);
        formDataSubmit.append("trading_partner_info", JSON.stringify(formData.value.keyData));
        formDataSubmit.append("address_name", formData.value.address_name);
        formDataSubmit.append("company", formData.value.company);
        formDataSubmit.append("address1", formData.value.address1);
        formDataSubmit.append("address2", formData.value.address2);
        formDataSubmit.append("city", formData.value.city);
        formDataSubmit.append("state", formData.value.state);
        formDataSubmit.append("state_code", formData.value.state_code);
        formDataSubmit.append("country", formData.value.country);
        formDataSubmit.append("country_code", formData.value.country_code);
        formDataSubmit.append("phone", formData.value.phone);
        formDataSubmit.append("postal_code", formData.value.postal_code);
        formDataSubmit.append("partner_image_path", formData.value.partner_image_path);
        formDataSubmit.append("oldImagePath", formData.value.oldImagePath);
        formDataSubmit.append("skuLength", formData.value.sku_length);
        formDataSubmit.append("artSku", formData.value.art_sku);
        formDataSubmit.append("productSku", formData.value.product_sku);
        ApiService.post(requestUrl, formDataSubmit, config)
          .then(({ data }) => {
            notificationFire(data.message, "success");
            oldTradingPartnerData.name = formData.value.name;
            oldTradingPartnerData.description = formData.value.email;
            if (typeof route.params.id === "undefined") {
              formData.value.trading_partner_id = data.data.trading_partner_id;
              formData.value.userID = data.data.id;
              setCurrentPageBreadcrumbs(formatText('edit-trading-partner'), [{"name" : formatText('trading-partners'), 'path': 'trading-partners'}, {"name": formatText('edit-trading-partner') , "active": true}]);
              router.push({
                name: "edit-trading-partner",
                params: { id: data.data.id },
              });
            }
            if (btnName == "save_back") {
              router.push({ name: "trading-partners" });
            }
            isToolbarButtonsSubmitAction
              ? emitEvent("loader", { save_loader: false, action: btnName })
              : btnName == "save"
              ? (loading.value = false)
              : (loadingback.value = false);
          })
          .catch((error) => {
            isToolbarButtonsSubmitAction
              ? emitEvent("loader", { save_loader: false, action: btnName })
              : btnName == "save"
              ? (loading.value = false)
              : (loadingback.value = false);
            let response = error.response;
            if (response != "") {
              let message = response.data.message;
              notificationFire(message, "error");
            }
          });
      });
    };

    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" : formatText('trading-partners'), 'path': 'trading-partners'}, {"name": formatText(route.name) , "active": true}]);
      let role = loggedInUserRoles()
      let per = loggedInUserPermissions();
      if (per.includes("createTradingPartner") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateTradingPartner") || role == "Super Admin")
        permission.isEditAllowed = true;

      if (route.params.id != "undefined") {
        tradingPartnerID.value = route.params.id;
      }else{
        imageSrcPath.value = defaultImage.value;
      }

      if (tradingPartnerID.value > 0) {
        await getTradingPartnerDetail();
      } else {
        getTradingPlatforms();
      }

      if(formData.value.clientID != ''){
        for(var i=0; i <= 2; i++){
          const target = document.getElementById('variable_'+i);
          var clipboard = new ClipboardJS(target);
          clipboard.on('success', function (e) {
              const currentLabel = target.innerHTML;
              if (target.innerHTML === '<i class="bi bi-check fs-2x text-success"></i>') {
                  return;
              }
              target.innerHTML = '<i class="bi bi-check fs-2x text-success"></i>';
              setTimeout(function () {
                  target.innerHTML = currentLabel;
              }, 3000)
          });
        }
      }

      generateHeaderToolbarButtons();
    });
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    });

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons() {
      return setCurrentPageActionButtons([
        {
          name: constants.general.SAVE,
          action: "save",
          class: "btn-primary me-3",
          isLoader: false,
          isAllowed:
            permission.isEditAllowed ||
            (permission.isAddAllowed && permission.isEditAllowed),
        },
        {
          name: constants.general.SAVEANDBACK,
          action: "save_back",
          class: "btn-primary me-3",
          isLoader: false,
        },
        {
          name: constants.general.CANCEL,
          action: "cancel",
          class: "btn-secondary",
          isLoader: false,
        },
      ]);
    }

    return {
      tableData,
      pageLoading,
      formData,
      pullOptions,
      rules,
      submitForm,
      formRef,
      loading,
      loadingback,
      deleteRow,
      remove,
      onAddItem,
      readonly,
      checkEmptySpace,
      platformData,
      permission,
      tradingPartnerID,
      oldTradingPartnerData,
      removeImage,
      onFileChange,
      imageSrcPath,
      constants
    };
  },
};
</script>
<style sass>
table .el-form-item {
  margin-top: 10px;
  margin-bottom: 10px;
}
table .el-form-item__content {
  justify-content: center;
}
table .el-form-item__error {
  font-weight: 400;
}
fieldset {
  min-width: auto; 
  margin: auto;
  border: 1px solid #181C32;
 
}
legend {
  float: none;
  width: auto;
}
  @media (min-width: 1200px){
legend {
  font-size: inherit;
  color: #181C32;
}
}
</style>